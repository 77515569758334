<template>
  <el-card>
    <div class="title">Agency information</div>
    <el-row class="search">
      <el-button type="primary" @click="query()" :loading="loading">Refresh
      </el-button>
      <el-button type="primary" v-if="Creates()" @click="dialogVisible = true" :loading="loading">Create</el-button>
    </el-row>
    <el-row>
      <el-table :data="list" style="width: 100%; margin-bottom: 20px" v-loading="loading" stripe row-key="agentId" border
        default-expand-all :tree-props="{ children: 'sonAgents', hasChildren: 'hasChildren' }"
        element-loading-text="loading" element-loading-spinner="el-icon-loading">
        <el-table-column prop="name" label="Agency name">
          <template slot-scope="scope">
            <span class="notranslate">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="agentId" label="Agency ID"></el-table-column>
        <!-- <el-table-column prop="pwd" label="Password">
          <template slot-scope="scope">
            <div class="notranslate">{{ scope.row.pwd }}</div>
          </template>
        </el-table-column> -->
        <el-table-column prop="inviteCode" label="Invite Code"></el-table-column>
        <el-table-column prop="registerHostess" label="Register hostess"></el-table-column>
        <el-table-column prop="reviewSuccessCount" label="Pass review"></el-table-column>
        <el-table-column prop="reviewingCount" label="Under review"></el-table-column>
        <el-table-column prop="noHaveReviewCount" label="Review pending"></el-table-column>
        <el-table-column prop="totalDiamondsIncome" label="Total diamonds income">
          <template slot-scope="scope">
            {{ $store.getters.getCurDiamondNum(scope.row.totalDiamondsIncome) }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-input style="width: 160px; margin: 0 0 20px" v-model="agentName" placeholder="agentName"></el-input>
      <div>
        <el-select style="width: 160px" multiple collapse-tags v-model="agentType" placeholder="">
          <el-option v-for="item in agentTypes" :key="item.value" :label="item.enName" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="create()">Create</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { getAgencyInformation, getAgencyregister } from '@/api/dashboard.js'

export default {
  data() {
    return {
      loading: false,
      list: [],
      dialogVisible: false,
      agentType: [
        JSON.parse(sessionStorage.getItem('loginData')).agentType[0].value
      ],
      agentName: '',
      agentTypes: JSON.parse(sessionStorage.getItem('loginData')).agentType
    }
  },
  created() {
    this.query()
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
      this.agentName = ''
      this.agentType = [this.agentTypes[0].value]
      this.agentTypes = JSON.parse(
        sessionStorage.getItem('loginData')
      ).agentType
    },
    Creates() {
      if (JSON.parse(sessionStorage.getItem('loginData')).canCreateSon == 1) {
        return true
      }
      return false
    },
    // 获取列表
    async query() {
      const { data: res } = await getAgencyInformation({})
      if (res.code !== 0)
        return this.$message({
          message: res.msg,
          center: true,
          type: 'error'
        })
      this.$message({
        message: res.msg,
        center: true,
        type: 'success'
      })
      window.sessionStorage.setItem('headIcon',JSON.stringify(res.data.headIcon))
      this.$store.commit('setHeadIcon', res.data.headIcon)
      this.list = [res.data]
    },

    // 获取创建
    async create() {
      let agentType = this.agentType.join()
      const { data: res } = await getAgencyregister({
        agentId: JSON.parse(sessionStorage.getItem('loginData')).agentId,
        agentType: agentType,
        agentName: this.agentName
      })
      if (res.code !== 0)
        return this.$message({
          message: res.msg,
          center: true,
          type: 'error'
        })
      this.$message({
        message: res.msg,
        center: true,
        type: 'success'
      })

      this.handleClose()
      this.query()
    }
  }
}
</script>
